@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .btn {
    @apply transition-colors duration-200 cursor-pointer bg-preflop_mage-purple px-6 py-1 rounded-2xl hover:bg-preflop_mage-purple_light text-white;
  }
  .btn-gold {
    @apply  transition-all cursor-pointer bg-preflop_mage-gold px-6 py-1 rounded-xl hover:bg-preflop_mage-gold_light text-black;
  }
  .divider-box{
    @apply absolute bottom-0 left-0 w-full overflow-hidden leading-[0px] rotate-180
  }
  .svg-box{
    @apply relative block w-full h-[106px]
  }
}

