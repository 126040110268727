.custom-shape-divider-top-1676547837 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0; 
    transform: rotate(180deg);
}

.custom-shape-divider-top-1676547837 svg {
    position: relative;
    display: block;
    width: calc(250% + 1.3px);
    height: 150px;
}

.custom-shape-divider-top-1676547837 .shape-fill {
    fill: #7058A4;
}

.custom-shape-divider-top-1676647955 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1676647955 svg {
    position: relative;
    display: block;
    width: calc(196% + 1.3px);
    height: 117px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1676647955 .shape-fill {
    fill: #7058a4;
}