.arrow-to-top.arrow-01.show {
    opacity: 1;
    pointer-events: all;
    bottom: 40px;
}
.arrow {
    font-size: "1.8rem";
    color: "black";
}
.arrow-to-top.arrow-01 a:hover {
    background-color: #bb900f;
    transform: scale(1.1)
}

.arrow-to-top.arrow-01 {
    cursor: pointer;
    pointer-events: none;
    position: fixed;
    text-align: center;
    z-index: 100;
    -webkit-transition: 0.3s all;
    transition: 0.3s all;
    right: 40px;
    bottom: 10px;
    opacity: 0;
}
.arrow-to-top.arrow-01 a {
    text-decoration: none;
    padding: 10px 12px;
    color: #ffffff;
    background: #ffc315;
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 50%);
    box-shadow: 0 0 2px rgb(0 0 0 / 50%);
    -webkit-transition: 0.3s all;
    transition: 0.3s all;
    border-radius: 8px;
    font-size: 1.2rem;
    display: inline-block;
}
@media screen and (max-width: 600px) {
    .arrow-to-top.arrow-01 {
        right: 20px;
    }
}