
  /*
  =============== 
  Slider
  ===============
  */
  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .title h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }
  .title span {
    font-size: 0.85em;
    color: blue;
    margin-right: 1rem;
    font-weight: 700;
  }
  .section-center {
    margin: 0 auto;
    margin-top: 4rem;
    width: 80vw;
    height: 450px;
    max-width: 800px;
    text-align: center;
    position: relative;
    display: flex;
    /* overflow: hidden; */
  }
  .person-img {
    /* border-radius: 50%; */
    margin-bottom: 1rem;
    /* padding: 1rem; */
    width: 150px;
    height: 150px;
    object-fit: cover;
    /* border: 4px solid gray; */
    box-shadow: black;
  }
  article h4 {
    text-transform: uppercase;
    
    margin-bottom: 0.25rem;
  }
  .title {
    /* text-transform: capitalize; */
    margin-bottom: 0.75rem;
    /* color: gray; */
  }
  .text {
    max-width: 35em;
    margin: 0 auto;
    margin-top: 2rem;
    line-height: 2;
    /* color: gray; */
  }
  .icon {
    
    font-size: 3rem;
    margin-top: 1rem;
   /* color: rgb(249 191 15 / var(--tw-text-opacity)); */
  }
  .prev,
  .next {
    position: absolute;
    top: 200px;
    transform: translateY(-50%);
    /* background: purple; */
    color: white;
    width: 1.25rem;
    height: 1.25rem;
    display: grid;
    place-items: center;
    border-color: transparent;
    font-size: 1rem;
    border-radius: 2rem;
    cursor: pointer;
    transition: all 0.5s linear;
    display:none;
  }
  /* .prev:hover,
  .next:hover {
    background: white;
  } */
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }
  @media (min-width: 800px) {
    .text {
      max-width: 45em;
    }
    .prev,
    .next {
      width: 2rem;
      height: 2rem;
      font-size: 1.5rem;
      display: block;
    }
  }
  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    /* z-index: 150; */
    transition: all 0.3s linear;
  }
  article.activeSlide {
    opacity: 1;
    height: max-content;
    transform: translateX(0);
    /* z-index: 500; */
  }
  article.lastSlide {
    transform: translateX(-100%);
  }
  article.nextSlide {
    transform: translateX(100%);
  }
  .custom-shape-divider-top-1676643218 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1676643218 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 183px;
}

.custom-shape-divider-top-1676643218 .shape-fill {
    fill: #5b4293;
}